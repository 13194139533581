import { mapActions, mapGetters } from 'vuex'
import { capitalize } from '@/utils'

export default {
  name: 'career-page',
  filters: {
    capitalize
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  components: {
    Dropdown: () => import(/* webpackChunkName: "DropDown" */ '@/components/dropdown/Dropdown'),
    TextField: () => import(/* webpackChunkName: "DropDown" */ '@/components/forms/TextField'),
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem'),
    Search: () => import(/* webpackChunkName: "icons" */ '@/components/icons/Search'),
    Location: () => import(/* webpackChunkName: "icons" */ '@/components/icons/Location'),
    Undelivered: () => import(/* webpackChunkName: "icons" */ '@/components/icons/Undelivered'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    Pagination: () => import(/* webpackChunkName: "pagination" */ '@/components/pagination/Pagination'),
    User: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Person')
  },
  data() {
    return {
      departmentList: ['All Department', 'Product', 'HR', 'Marketing', 'Finance', 'Technology Project', 'B2B', 'B2C', 'Education', 'Legal'],
      selectedDepartment: 'All Department',
      partners: ['Astrapay', 'DANA', 'Gojek', 'Gotoko-Logo', 'Telkom', 'Prakerja-Logo', 'Nexsoft', 'BTPN', 'Best', 'ANTV', 'Manulife', 'BRI', 'Blibli', 'Indosat', 'Bankjatim'],
      selectedLocation: '',
      isSearching: false,
      visibleSuggestion: false,
      searchTerm: '',
      totalRecords: 0,
      currentPage: 1,
      hasMorePages: true,
      keyword: '',
      isFetching: false,
      debounce: null,
      cityKeyword: null,
      locationSearch: null,
      isFromSearch: false
    }
  },
  computed: {
    ...mapGetters('career', ['vacancies', 'regencies']),
    ...mapGetters('common', ['isMobile']),
    pageSize() {
      return this.isMobile ? 6 : 9
    },
    filteredData() {
      return this.vacancies?.content
    },
    start() {
      return (this.currentPage - 1) * this.pageSize + 1
    },
    end() {
      const lastOnPage = (this.currentPage - 1) * this.pageSize + this.pageSize
      return lastOnPage > this.totalRecords ? this.totalRecords : lastOnPage
    }
  },
  mounted() {
    this.getJobList()
  },
  watch: {
    currentPage() {
      this.getJobList()
    }
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('career', ['getVacancies', 'getRegencies']),
    searchCity(value, target) {
      this.cityKeyword = null
      if (value) this.isSearching = true
      this.visibleSuggestion = false
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        if (value) {
          this.cityKeyword = value
          this.getRegencies({
            params: {
              searchQuery: this.locationSearch.toUpperCase()
            }
          }).then(() => {
            this.visibleSuggestion = true
            this.isSearching = false
          })
        }
      }, 300)
    },
    selectCity(data) {
      this.selectedLocation = data.id
      this.locationSearch = data.displayName
      this.visibleSuggestion = false
    },
    onPageChange(page) {
      this.currentPage = page
    },
    reset() {
      this.currentPage = 1
      this.selectedLocation = ''
      this.locationSearch = null
      this.selectedDepartment = 'All Department'
      this.keyword = ''
      this.getJobList()
    },
    doSearch() {
      this.currentPage = 1
      if (!this.locationSearch) this.selectedLocation = ''
      this.isFromSearch = true
      this.getJobList()
    },
    getJobList() {
      this.isFetching = true
      this.showLoading()
      const params = {
        query: this.keyword,
        division: this.selectedDepartment === 'All Department' ? '' : this.selectedDepartment,
        location: this.selectedLocation,
        status: 'OPEN',
        page: this.currentPage - 1,
        size: this.pageSize
      }
      this.getVacancies({
        params: params
      }).then((res) => {
        this.hideLoading()
        this.isFetching = false
        this.totalRecords = res.data.totalElements
      })
    },
    seeDetail() {
      this.$router.push('/g2lab')
    },
    partner() {
      this.$router.push('/register-alliance')
    },
    detailPage(position, id) {
      this.$router.push(
        `/career/${position
          .replace(/[^a-z0-9_]+/gi, '-')
          .replace(/^-|-$/g, '')
          .toLowerCase()}/${id}`
      )
    }
  }
}
